$(function(){
  $('.kv').slick({
    autoplay: true,
    autoplaySpeed: 4500,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    accessibility: true,
    arrows: true,
    dots: false,
    responsive: [{
      breakpoint: 1200,
      settings: {
        swipe: true,
      }
    }]
  });
});

$(function(){
  $('.news-wrapper').slick({
    autoplay: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    accessibility: true,
    arrows: true,
    dots: false,
    responsive: [{
      breakpoint: 1080,
      settings: {
        swipe: true,
      },
      breakpoint: 769,
      settings: {
        slidesToShow: 1,
      }
    }]
  });
});
